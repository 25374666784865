// config.js

const bizIcon = "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg";
const imageOne = "https://media.istockphoto.com/id/1147825753/photo/curved-road-way-leading-into-new-development-austin-mueller-suburb.jpg?s=612x612&w=0&k=20&c=3MpBudVYOmQ6Hut5ndSMoR3k81bmNrlPxgJuiG16KiE=";
const imageTwo = "https://media.istockphoto.com/id/1319878920/photo/modern-suburbs-green-trees-and-modern-landscape.jpg?s=612x612&w=0&k=20&c=H58c-GLA9NwgF11Prbhzv7S9Z0CETBwLPmLIAN__MbQ=";
const imageThree = "https://media.istockphoto.com/id/1340589191/photo/generic-suburban-housing-development-aerial.jpg?s=612x612&w=0&k=20&c=CCDJSHk2eNwLKKzlotoyfG_M8gR7yr44z-Ntdm1YcBw=";
const imageFour = "https://media.istockphoto.com/id/832689112/photo/aerial-suburb-austin-texas-hill-country.jpg?s=612x612&w=0&k=20&c=gCoE5mcklqyjonR9td1_zNDezKkm_CVHaLwFkT5fR9w=";
const imageFive = "https://primary.jwwb.nl/public/y/i/d/temp-igrjuntxuqsxjliyolet/headshot-high-g2cqx9.jpg?enable-io=true&enable=upscale&crop=1920%2C2557%2Cx0%2Cy1%2Csafe&width=208&height=277";

export const siteConfig = {
  title: "Chris Schoenfeld Realtor",
  metaDescription: "Chris Schoenfeld Realtor",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Chris Schoenfeld Realtor",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Chris Schoenfeld Realtor",
    headline: "Your Trusted Real Estate Expert in Texas",
    address: "Kyle, Texas, United States",
    description: "With years of experience in residential and commercial real estate, Chris Schoenfeld provides personalized, professional service to guide you through every step of the home buying or selling process. Specializing in luxury homes, first-time buyers, and investment properties.",
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Real Estate Expert in Kyle, Texas",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 'Chris Schoenfeld brings expert knowledge of the Texas real estate market, providing valuable insights on market trends, property values, and investment opportunities to guide your decisions.',
    },
    {
      description: 'Leverage cutting-edge tools to customize your property search, track market fluctuations, and determine accurate home valuations for better outcomes.',
    },
    {
      description: 'As a skilled negotiator, Chris ensures your best interests are represented throughout the buying or selling process, delivering optimal results for every client.',
    },
  ],
  services: {
    sectionTitle: "Services",
    description: "Expert real estate solutions tailored to your needs, from personalized property searches to skilled negotiation and seamless guidance through closing.",
    callouts: [
      {
        name: 'Home Buying',
        description: 'Find your dream home with customized searches, expert market analysis, and personalized support every step of the way.',
        imageSrc: imageTwo,
        imageAlt: 'Family exploring their new home in Texas',
      },
      {
        name: 'Home Selling',
        description: 'Receive expert advice on pricing, staging, and marketing to ensure a smooth and profitable home-selling experience.',
        imageSrc: imageThree,
        imageAlt: 'Beautifully staged home ready for sale',
      },
      {
        name: 'Investment Properties',
        description: 'Access exclusive investment opportunities, expert property management advice, and strategies to maximize your ROI.',
        imageSrc: imageFour,
        imageAlt: 'Modern apartment complex for investment',
      }
    ],
  },
  about: {
    sectionTitle: "About Chris Schoenfeld",
    description: "With over 7 years of experience in real estate investment and property management, I bring expertise in market analysis, strategic marketing, and business development to every transaction. Based in Austin, TX, I have an in-depth understanding of the dynamic Texas real estate market. My tailored marketing strategies and commitment to detailed analysis have helped clients achieve exceptional results, whether buying, selling, or investing in properties.",
    image: {
      src: imageFive,
      alt: "Chris Schoenfeld, real estate agent",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Kyle, Texas, United States",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3444.567890098657!2d-97.8745273!3d29.9891049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865cbe94e0b8ad47%3A0x87a3a671e841c34d!2sKyle%2C%20Texas%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Kyle,%20Texas&ll=29.9891,-97.8745&q=Kyle%20Texas' 
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Kyle,+Texas&FORM=HDRSC6' 
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Kyle,+Texas/@29.9891049,-97.8745273,15a,814.57589625d,35y,0h,0t,0r' 
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Kyle,+Texas/@29.9891049,-97.8745273,15z/' 
      }
    ]
  },
  contact: {
    sectionTitle: "Ready to Find Your Dream Home?",
    description: "Contact us today to start your real estate journey with Chris Schoenfeld Realtor.",
    socialLinks: {
      facebook: "https://www.chrisschoenfeldrealtor.com/",     
      instagram: "https://www.chrisschoenfeldrealtor.com/",
      twitter: "https://www.chrisschoenfeldrealtor.com/",
      linkedin: "https://www.chrisschoenfeldrealtor.com/",
      youtube: "https://www.chrisschoenfeldrealtor.com/"
    },
    contactNumbers: [
      "Mobile: (512) 497-8707",
    ],
    logo: bizIcon,
    emailRecipient: "Chris@mammothrealty.net"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
